import Flickity from "flickity"
import throttle from "lodash/throttle"
import debounce from "lodash/debounce"

class AnchorCarousel {
	constructor(block) {
		this.block = block
		this.items = block.querySelector(".anchor-carousel__items")
		this.links = block.querySelectorAll(".anchor-carousel__link")
		this.sections = document.querySelectorAll(".two-column-section")

		this.detectSticky()
		this.detectCurrentSection()
		this.carouselInit()
		this.events()
	}

	events() {
		this.links.forEach(link => {
			link.addEventListener("click", e => this.scrollTo(link.dataset.target, e))
		})

		window.addEventListener(
			"resize",
			debounce(() => this.flkty?.resize(), 250)
		)
	}

	detectSticky() {
		const observer = new IntersectionObserver(
			([e]) => {
				e.target.classList.toggle("is-pinned", e.intersectionRatio < 1)
			},
			{ threshold: [1] }
		)
		observer.observe(this.block)
	}

	detectCurrentSection() {
		this.sections.forEach((section, index) => {
			const observer = new IntersectionObserver(
				([e]) => {
					if (e.intersectionRatio > 0) {
						this.links.forEach(link => {
							link.classList.toggle("anchor-carousel__link--active", link.dataset.target == section.id)
						})
						this.triggeredBy = "scroll"
						this.flkty.select(index)
					} else {
						this.links.forEach(link => {
							if (link.dataset.target == section.id) {
								link.classList.remove("anchor-carousel__link--active")
							}
						})
					}
				},
				{ threshold: [0], rootMargin: "-33% 0% -67%" }
			)
			observer.observe(section)
		})
	}

	scrollTo(target, e = null) {
		if (e) {
			e.preventDefault()
		}

		const scrollTarget = document.querySelector(`#${target}`)
		if (scrollTarget) {
			const y = scrollTarget.getBoundingClientRect().top + window.scrollY - 100
			window.scroll({
				top: y,
				behavior: "smooth",
			})
		}
	}

	carouselInit() {
		this.flkty = new Flickity(this.items, {
			cellAlign: "center",
			contain: false,
			adaptiveHeight: false,
			pageDots: false,
			groupCells: false,
			prevNextButtons: true,
			watchCSS: true,
		})

		this.flkty.on("select", index => {
			if (this.triggeredBy != "scroll") {
				const el = this.flkty.cells[index].element
				const target = el.querySelector(".anchor-carousel__link")?.dataset.target

				if (target) {
					this.scrollTo(target)
				}
			}

			this.triggeredBy = "carousel"
		})
	}
}

export default AnchorCarousel
