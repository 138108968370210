class Header {
	constructor(header) {
		this.header = header
		this.navItems = header.querySelectorAll(".site-header__nav-menu-item")
		this.megaMenus = header.querySelectorAll(".mega-menu")
		this.mobileNavButton = header.querySelector(".site-header__mobile-nav-button")
		this.mobileMenu = header.querySelector(".mobile-menu")
		this.mobileMenuOpen = false
		this.events()
	}

	events() {
		this.navItems.forEach(item => {
			const link = item.querySelector(".site-header__nav-menu-link")
			link?.addEventListener("click", e => this.handleNavLinkClick(e, item, link))
		})

		this.mobileNavButton.addEventListener("click", () => this.toggleMobileMenu())

		document.addEventListener("click", e => this.handlePageClick(e))
	}

	handleNavLinkClick(e, item, link) {
		if (item.classList.contains("site-header__nav-menu-item--has-children")) {
			e.preventDefault()
			item.classList.toggle("site-header__nav-menu-item--active")
			this.megaMenus.forEach(menu => {
				if (menu.dataset.parent == item.dataset.id) {
					menu.classList.toggle("mega-menu--active")
				} else {
					menu.classList.remove("mega-menu--active")
				}
			})
		}
	}

	toggleMobileMenu() {
		this.mobileNavButton.classList.toggle("site-header__mobile-nav-button--active", !this.mobileMenuOpen)
		this.mobileMenu.classList.toggle("mobile-menu--active", !this.mobileMenuOpen)
		this.mobileMenuOpen = !this.mobileMenuOpen
	}

	handlePageClick(e) {
		if (e.target != this.header && !e.target.closest(".site-header")) {
			this.megaMenus.forEach(menu => {
				menu.classList.remove("mega-menu--active")
			})

			this.navItems.forEach(item => {
				item.classList.remove("site-header__nav-menu-item--active")
			})
		}
	}
}

export default Header
