class Hero {
	constructor(block) {
		this.block = block
		this.scroll = block.querySelector(".hero__scroll")
		this.events()
	}

	events() {
		if (this.scroll) {
			this.scroll.addEventListener("click", () => this.scrollToTarget(this.scroll.getAttribute("href")))
		}
	}

	scrollToTarget(targetID) {
		const target = document.querySelector(targetID)

		if (target) {
			target.scrollTop = 120
		}
	}
}

export default Hero
