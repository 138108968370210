//import { DisplayLabel } from "./components/DisplayLabel";
import jQuery from "jquery"
import "bootstrap"
import "slick-carousel"
// import AOS from "aos"

//Components
import Header from "./components/Header"
import Hero from "./components/Hero"
import AnchorCarousel from "./components/AnchorCarousel"

for (const header of document.querySelectorAll(".site-header")) {
	new Header(header)
}

for (const block of document.querySelectorAll(".hero")) {
	new Hero(block)
}

for (const block of document.querySelectorAll(".anchor-carousel")) {
	new AnchorCarousel(block)
}

// AOS animation js
// AOS.init();
// function addAosAnimation(mianWrapper, childe, animationType, duration, delay) {
// 	let myDiv = jQuery(mianWrapper);
// 	let innerElements = myDiv.find(childe);
// 	innerElements.each(function (index) {
// 		jQuery(this).attr('data-aos', animationType);
// 		jQuery(this).attr('data-aos-delay', index * delay);
// 		jQuery(this).attr('data-aos-duration', duration);
// 		jQuery(this).attr('data-aos-offset', '150s');
// 	});
// }
// // animation in multiple items
// // addAosAnimation('parent wrapper', 'child item', 'animationType', duration, delay);
// addAosAnimation('.service-cards', '.col-md-4', 'fade-up', 1500, 300);
// addAosAnimation('.offer-cards', '.col-sm-6', 'fade-left', 1500, 700);
// addAosAnimation('.blog-row-main', '.n-card', 'fade-up', 1500, 700);

// AOS.init({
// 	offset: 200,
// 	disable: false,
// 	startEvent: 'DOMContentLoaded',
// 	initClassName: 'aos-init',
// 	animatedClassName: 'aos-animate',
// 	useClassNames: false,
// 	disableMutationObserver: false,
// 	debounceDelay: 50,
// 	throttleDelay: 99,
// });
/* let Main = {
  init: async function () {
    // initialize demo javascript component - async/await invokes some
    //  level of babel transformation
    const displayLabel = new DisplayLabel();
    await displayLabel.init();
  },
};

Main.init(); */

/* jQuery(($) => {
  $("body").css("background", "green");
}); */

jQuery(document).ready(function () {
	jQuery(window).scroll(function () {
		if (jQuery(document).scrollTop() > 50) {
			jQuery(".site-header").addClass("fixed")
		} else {
			jQuery(".site-header").removeClass("fixed")
		}
	})
})

// console.log(jQuery( 'body' ));
var headerHeight = jQuery(".site-header").height()
jQuery("body").css("padding-top", headerHeight)

window.addEventListener("resize", () => {
	var headerHeight = jQuery(".site-header").height()
	jQuery("body").css("padding-top", headerHeight)
})

jQuery(".news-listing-content").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	fade: true,
	asNavFor: ".new-image-slider",
})

jQuery(".new-image-slider").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	asNavFor: ".news-listing-content",
	dots: false,
	arrows: true,
	focusOnSelect: true,
})
// sector slider
jQuery(".slider-tabs").slick({
	slidesToShow: 4,
	slidesToScroll: 1,
	arrows: true,
	dots: false,
	asNavFor: ".slider-content",
	focusOnSelect: true,
	responsive: [
		{
			breakpoint: 1140,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 670,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 570,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
})

jQuery(".slider-content").slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	asNavFor: ".slider-tabs",
	dots: false,
	arrows: false,
	fade: true,
})

jQuery(".n-c-slider").slick({
	slidesToShow: 4,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	touchThreshold: 200,
	responsive: [
		{
			breakpoint: 1140,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 670,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 570,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
})

/**
 * AJAX Call General With Pagination
 */
// jQuery(document).ready(function($)
var acgwp_currentPage = 1
var acgwp_totalItems // Total items in the CPT
var acgwp_displayedItems = 0 // Items currently displayed

function acgwp_performAjax(ajaxurl, acgwp_post_type, acgwp_loader_div, acgwp_result_div, acgwp_pagination_div) {
	jQuery.ajax({
		type: "GET",
		url: ajaxurl,
		data: {
			action: "ajax_call_general_func",
			post_type: acgwp_post_type,
			page: acgwp_currentPage,
		},
		success: function (response) {
			var responseData = JSON.parse(response)
			jQuery(acgwp_loader_div).hide()

			acgwp_totalItems = parseInt(responseData.total_items)

			jQuery(acgwp_result_div).append(responseData.content)
			if (acgwp_post_type === "case-studies") {
				jQuery(acgwp_result_div).addClass("case-studies-grid")
				jQuery(acgwp_result_div).removeClass("card-wrapper")
				jQuery(acgwp_result_div).removeClass("blog-row-main")
			}

			acgwp_displayedItems = jQuery(acgwp_result_div).children().length

			// Pass the archive URL to updatePagination
			updatePagination(acgwp_displayedItems, acgwp_totalItems, acgwp_pagination_div, responseData.archive_url)

			// Bind click event for newly added pagination control
			jQuery(acgwp_pagination_div)
				.off("click", ".acgwp_pc")
				.on("click", ".acgwp_pc", function (e) {
					e.preventDefault()
					jQuery(acgwp_loader_div).show()
					acgwp_currentPage++
					acgwp_performAjax(ajaxurl, acgwp_post_type, acgwp_loader_div, acgwp_result_div, acgwp_pagination_div)
				})
		},
		error: function (xhr, textStatus, errorThrown) {
			console.error("Ajax request failed:", textStatus, errorThrown)
		},
	})
}

function updatePagination(displayedItems, totalItems, paginationDiv, archiveUrl) {
	var paginationControls = '<div class="pagination-inner">'

	const reusableBlock = document.querySelector(".reusable-block")
	const isSinglePage = reusableBlock ? reusableBlock.getAttribute("data-is-single-page") === "true" : false

	if (isSinglePage) {
		// For a single post page, show "View More" button linked to the archive page
		paginationControls += '<a href="' + archiveUrl + '" class="cta-btn view-more">View More</a>'
	} else {
		// Normal behavior for multiple pages
		if (displayedItems < totalItems) {
			paginationControls += '<a href="#" class="cta-btn next-page acgwp_pc" data-count="1">Load More</a>'
		}
		paginationControls += '<div class="show-page">'
		paginationControls += '<span class="current-items">' + displayedItems + "</span>"
		paginationControls += '<span class="separator"> of </span>'
		paginationControls += '<span class="total-items">' + totalItems + " shown</span>"
		paginationControls += "</div>"
		paginationControls += '<a href="#" class="text-btn">Back to top</a>'
	}

	jQuery(paginationDiv).html(paginationControls)
}

ajaxNews()

function ajaxNews() {
	if (jQuery(".news-card-listing").length) {
		var post_type = "news"
		var loader_div = ".news-loader"
		var result_div = "#search-results"
		var pagination_div = ".pagination"
		acgwp_performAjax(ajaxurl, post_type, loader_div, result_div, pagination_div)
	}
}

ajaxCaseStudy()

function ajaxCaseStudy() {
	if (jQuery(".cs-card-listing").length) {
		var post_type = "case-studies"
		var loader_div = ".cs-loader"
		var result_div = "#cs-search-results"
		var pagination_div = ".cs-pagination"
		acgwp_performAjax(ajaxurl, post_type, loader_div, result_div, pagination_div)
	}
}

ajaxCaseStudies()

function ajaxCaseStudies() {
	if (jQuery(".case-studies-grid").length) {
		var post_type = "case-studies"
		var loader_div = ".case-studies-loader" // Update selector as needed
		var result_div = "#case-studies-results" // Update selector as needed
		var pagination_div = ".case-studies-pagination" // Update selector as needed
		acgwp_performAjax(ajaxurl, post_type, loader_div, result_div, pagination_div)
	}
}

// Reveal text on click
jQuery(document).ready(function ($) {
	$(".reveal-text-btn").on("click", function (e) {
		e.preventDefault()

		$(this).closest(".inner-details").find(".text-effect").toggleClass("expanded")

		if ($(this).text() === this.dataset.more) {
			$(this).text(this.dataset.less)
		} else {
			$(this).text(this.dataset.more)
		}
	})
})

// Add span to mega menu link
const mmSpacer = document.querySelector("li.mm-spacer")
if (mmSpacer) {
	const megaMenuLink = mmSpacer.querySelector("a.mega-menu-link")
	if (megaMenuLink) {
		const spanElement = document.createElement("span")
		spanElement.textContent = megaMenuLink.textContent
		mmSpacer.replaceChild(spanElement, megaMenuLink)
	}
}

// Add mobile menu footer section
document.addEventListener("DOMContentLoaded", () => {
	const { privacy_links, social_links } = acfData
	const current_year = new Date().getFullYear()
	let privacyLinksHtml = ""
	privacy_links.forEach((link, index) => {
		if (link) {
			const { url, title, target } = link.ps_link
			privacyLinksHtml += `
							${index > 0 ? "<span>|</span>" : ""}
							<a href="${encodeURI(url)}" target="${target || "_self"}">${title}</a>
					`
		}
	})

	let socialLinksHtml = ""
	social_links.forEach(link => {
		if (link) {
			const { url, target } = link.social_links_url
			socialLinksHtml += `
							<li>
									<a href="${encodeURI(url)}" target="${target || "_self"}">
											${link.social_links_icon}
									</a>
							</li>
					`
		}
	})

	const mobileSectionHtml = `
			<div class="mm-footer-bottom">
					<div class="container">
							<div class="mm-footer-bottom__content">
									<p class="mm-footer-bottom__copy">&copy; ${current_year} Projective Limited, All rights reserved</p>
									<div class="mm-footer-bottom__privacy-links">${privacyLinksHtml}</div>
									
									<ul class="mm-footer-bottom__social-icons">${socialLinksHtml}</ul>
							</div>
					</div>
			</div>
	`

	if (window.innerWidth <= 768) {
		const menu = document.querySelector("#mega-menu-primary_navigation")
		if (menu) {
			menu.insertAdjacentHTML("beforeend", mobileSectionHtml)
		}
	}
})

// Add cloned title to footer
document.addEventListener("DOMContentLoaded", () => {
	const addressLeftFirstChild = document.querySelector(".address__address-left").firstElementChild
	const clonedElement = addressLeftFirstChild.cloneNode(true)

	clonedElement.style.display = "none"

	const mainFooterAddress = document.querySelector(".main-footer__address")
	mainFooterAddress.appendChild(clonedElement)

	const updateVisibility = () => {
		const mediaQuery = window.matchMedia("(max-width: 768px)")

		if (mediaQuery.matches) {
			addressLeftFirstChild.style.display = "none"
			clonedElement.style.display = ""
		} else {
			addressLeftFirstChild.style.display = ""
			clonedElement.style.display = "none"
		}
	}

	// window.addEventListener("resize", updateVisibility)
	// updateVisibility()
})
